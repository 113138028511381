<template>
  <section class="py-20 px-4 lg:px-20 bg-dunkelgruen border-b-2 border-white">
    <div class="flex justify-center">
      <h2
        class="w-full lg:w-3/5 leading-relaxed text-center font-bold text-3xl text-white"
      >
        Mit Know-How, Erfahrung und Einsatz setzen wir (Ihre) Visionen um. Aber
        wir sind mehr. Wir sind Ihr kompetenter Partner bei der Digitalen
        Transformation Ihres Unternehmens. Zusammen erreichen wir mehr.
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section2",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
