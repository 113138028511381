<template>
  <section class="py-24 px-4 lg:px-40 bg-hellblau border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-between  items-center "
    >
      <div
        class="flex lg:hidden flex-col w-full lg:w-2/5 justify-start mb-16 lg:mt-0 "
      >
        <p class="text-6xl lg:text-8xl font-thin text-white  uppercase">
          Die Zukunft
        </p>
        <p class="text-6xl md:ml-10 lg:text-8xl font-thin text-white  uppercase">
          ist digital
        </p>
      </div>
      <div class="flex flex-col   lg:w-3/5   lg:mt-0 i  ">
        <p class="text-2xl font-bold leading-loose text-anthrazit">
          Wachstumsbremsen lösen. Digitalisierung erfolgreich meistern.
        </p>

        <p class="text-lg leading-loose mt-8 text-anthrazit">
          ZW Systems hat sich der digitalen Transformation verschrieben. Mit
          unserem Team bestehend aus IT-Experten sind wir Partner, Analytiker,
          Berater und Wegbereiter in Ihre digitale Zukunft.
        </p>

        <p class="text-lg leading-loose mt-8 text-anthrazit">
          Zielorientiert erarbeiten wir ein auf Sie zugeschnittenes
          Digitalisierungspaket, um neue Potenziale und Möglichkeiten für Ihr
          Unternehmen zu erschließen, darunter:
        </p>
        <ul class="text-lg leading-loose mt-8 text-anthrazit list-disc ml-10">
          <li>Prozess-Digitalisierung</li>
          <li>Software-Entwicklung</li>

          <li>Online-Marketing</li>

          <li>E-Commerce</li>

          <li>Consulting</li>
        </ul>
      </div>
      <div
        class="hidden lg:flex flex-col w-full lg:w-3/5 px-20 mt-16 lg:mt-0  "
      >
        <p
          class="text-4xl lg:text-7xl font-thin text-white  uppercase text-right"
        >
          Die Zukunft
        </p>
        <p
          class="text-4xl  lg:text-7xl font-thin text-white  uppercase text-right"
        >
          ist digital
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section1",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
